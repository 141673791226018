import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import Title1 from '@/components/title1/title1.vue';
import ShowPath from '@/components/showPath/showPath.vue';
import tiposNormas from '@/statics/normativas/tipoNormas.json';
import normas from '@/statics/normativas/normas.json';
import {
  Icons
} from '@/components/base';
  
@Component({
  components: {
    Layout,
    Title1,
    Icons,
    ShowPath
  }
})
export default class IdNormativa extends Vue {

  get tipoNorma(){
    return tiposNormas.find( (item: any) => this.$route.params.idTipo == item.id)
  }

  get normas(){
    return normas.filter( (item: any) => this.$route.params.idTipo == item.norma_tipo_id).reverse()
  }

  openTabDocumento(norma: any){
    console.log(norma);
    const url = 'https://antigo.laranjadaterra.es.gov.br/'+norma.arquivo_pdf;
    window.open(url, '_blank')?.focus();
  }

}
  